import { User, getAuth } from 'firebase/auth';
import { useEffect, useMemo } from 'react';
import {
  setLoadingAuth,
  setLoggedInState,
  setUid,
  setUserName,
  setUserToken,
} from '../store/reducers/userReducer';
import axios from 'axios';
import { useAppDispatch } from './redux';
import LogRocket from 'logrocket';

const identifyUser = (user: User) => {
  LogRocket.identify((user as User).uid, {
    name: (user as User).displayName || 'unknown',
    email: (user as User).email || 'unknown',
  });
};
const useAuthHandler = () => {
  const auth = useMemo(() => getAuth(), []);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // console.log('auth service tracking')
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      dispatch(setLoadingAuth());
      if (user) {
        user.getIdToken().then((accessToken: string) => {
          // axios.defaults.headers.common[
          //   'Authorization'
          // ] = `Bearer ${accessToken}`;
          axios.defaults.headers.common['Authorization'] =
            'Bearer CHANGE_ME_ASAP';
          dispatch(setUserToken(accessToken));
          identifyUser(user);
          // handle token refresh automatically
          // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#onidtokenchanged
          auth.onIdTokenChanged(async (user: any) => {
            if (user) {
              try {
                // set refresh token
                const newAccessToken = await user.getIdToken(true);
                dispatch(setUserToken(newAccessToken));
                // axios.defaults.headers.common[
                //   'Authorization'
                // ] = `Bearer ${newAccessToken}`;
                axios.defaults.headers.common['Authorization'] =
                  'Bearer CHANGE_ME_ASAP';
                // User is signed in or token was refreshed.
              } catch (error) {
                auth.signOut();
              }
            }
          });
          // token refresh handling ends
          dispatch(setUid(user.uid));
          dispatch(setUserName(user?.displayName || 'unknown'));
          dispatch(setLoggedInState(true));
        });
      } else {
        axios.defaults.headers.common['Authorization'] = ``;
        dispatch(setLoggedInState(false));
        dispatch(setUserName(user?.displayName || 'unknown'));
        dispatch(setUid(null));
        dispatch(setUserToken(null));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, auth]);

  return {};
};

export default useAuthHandler;
