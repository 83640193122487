import React from 'react';
import { Box } from '@mui/material';
import Sidebar from '../Components/Sidebar';
import MainContentChat from '../Components/MainContentChat';
import { useAppSelector } from '../hooks/redux';
import Sources from '../Components/Sources';

const Project: React.FC = () => {
  const showSources = useAppSelector(
    (state) => state.sourcesDrawer.sourcesDrawerOpen
  );

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <Sidebar />
        <Box
          component="main"
          sx={{
            bgcolor: '#F6F6F6',
            flexGrow: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            boxSizing: 'border-box', // Ensure padding and border are included in width
          }}
        >
          <Box
            sx={{
              flex: showSources ? 6 : 1,
              boxSizing: 'border-box', // Ensure padding and border are included in width
              minHeight: '100%',
              pr: showSources ? 3 : 0,
              borderRadius: 2,
              mr: showSources ? 3 : 0,
            }}
          >
            {/* <Box
          > */}
            <MainContentChat />
          </Box>
          {showSources && (
            <Box
              sx={{
                flex: 4,
                minHeight: '100%',
                p: 3,
                bgcolor: '#ffffff',
                boxSizing: 'border-box', // Ensure padding and border are included in width
                // Remove marginRight to prevent layout shift
                height: '100vh',
                overflowY: 'auto',
                // style scrolling to look nice
                '&::-webkit-scrollbar': {
                  width: '0px',
                },
              }}
            >
              <Sources />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Project;
