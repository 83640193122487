// Sidebar.tsx

import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Box,
} from '@mui/material';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import WorkspacesIcon from '@mui/icons-material/Workspaces'; // Or GroupWorkIcon if WorkspacesIcon is not available
import SettingsIcon from '@mui/icons-material/Settings';
import FolderIcon from '@mui/icons-material/Folder';
import { useAppSelector } from '../hooks/redux';

const sidebarWidth = 100; // Adjust based on your design

const Sidebar: React.FC = () => {
  const username = useAppSelector((state) => state.user.username);
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: sidebarWidth,
          boxSizing: 'border-box',
          bgcolor: '#ECECEC', // Set the background color of the drawer
          border: 'none',
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <List>
            <ListItem
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: '48px', // Adjust size as needed
                  height: '48px', // Adjust size as needed
                  borderRadius: '50%',
                  bgcolor: '#C59090',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '80px',
                  fontWeight: 'bold',
                  color: '#ffffff',
                  zIndex: '1', // Ensure this circle is above the next one
                }}
              >
                {/* Get the first letter from the username */}
                {username.charAt(0).toUpperCase()}
              </Box>
            </ListItem>
            {[
              { icon: <SettingsEthernetIcon />, label: 'Integrations' },
              { icon: <WorkspacesIcon />, label: 'Workspaces' },
              { icon: <SettingsIcon />, label: 'Settings' },
              { icon: <FolderIcon />, label: 'Folders' },
            ].map((item, index) => (
              <Tooltip title={item.label} placement="right" key={index}>
                <ListItem button sx={{ justifyContent: 'center', padding: 0 }}>
                  <Box
                    sx={{
                      // Border radius for the icon box
                      // Background color for the icon box
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '8px', // Padding inside the icon box
                      marginTop: '30px',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 'auto',
                        color: '#696996',
                      }}
                    >
                      {React.cloneElement(item.icon, {
                        fontSize: 'small',
                        style: { fontSize: '22px' },
                      })}
                    </ListItemIcon>
                  </Box>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
