import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import queriesReducer from './reducers/queriesReducer';
import sourcesDrawerReducer from './reducers/sourcesDrawerReducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    queriesStore: queriesReducer,
    sourcesDrawer: sourcesDrawerReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
