import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  isLoggedIn: boolean;
  loading: boolean;
  loggedInState: boolean;
  loginError?: string | null;
  uid: string | null;
  token: string | null;
  username: string;
}

const initialState: IInitialState = {
  isLoggedIn: false,
  loading: true,
  loggedInState: false,
  loginError: null,
  uid: null,
  token: null,
  username: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    setLoadingAuth: (state) => {
      state.loading = true;
    },
    setLoggedInState: (state, action: PayloadAction<boolean>) => {
      state.loggedInState = action.payload;
      state.loading = false;
      state.loginError = null;
      state.isLoggedIn = action.payload && true;
    },
    setLoginError: (state, action: PayloadAction<string>) => {
      state.loginError = action.payload;
      state.loading = false;
      state.isLoggedIn = false;
    },
    setUid: (state, action: PayloadAction<string | null>) => {
      state.uid = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logout,
  setLoadingAuth,
  setLoggedInState,
  setLoginError,
  setUid,
  setUserName,
  setUserToken,
} = userSlice.actions;

export default userSlice.reducer;
