import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import SourceBubble from './SourceBubble';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  closeSourcesDrawer,
  setSourcesDrawerQueryId,
} from '../store/reducers/sourcesDrawerReducer';

const Sources: React.FC = () => {
  // add new state called filter (useState) that defaults to "all" type string
  const [filter, setFilter] = React.useState<string>('all');
  const drawerQueryId = useAppSelector((state) => state.sourcesDrawer.queryId);
  const dispatch = useAppDispatch();
  const querySources = useAppSelector(
    (state) =>
      state.queriesStore.queries.find((query) => query.id === drawerQueryId)
        ?.sources
  );

  // filter querySources by source. if 'all' do not filter. otherwise filter by query type === filter
  const filteredSources = querySources?.filter(
    (source) => filter === 'all' || source.type === filter
  );
  if (querySources && querySources?.length > 0) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Resources ({querySources.length})
          </Typography>
          <Box>
            <CloseIcon
              sx={{ cursor: 'pointer', color: 'gray' }}
              onClick={() => {
                dispatch(closeSourcesDrawer());
                dispatch(setSourcesDrawerQueryId(''));
              }}
            />
          </Box>
        </Box>
        <Box sx={{ mb: 4, mt: 2 }}>
          {/* Menu box, add the following elements in boxes with ml:1 and the border
          bottom of the first element is #935FC8 (like tabs style), font size is 15px bold */}
          {/* This is how selected filter looks like */}
          {/* <Box
            sx={{
              display: 'inline',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '4px solid #935FC8',
              fontSize: '15px',
              fontWeight: 'bold',
              mb: 2,
              p: 1,
              pl: 0,
              mr: 2,
              cursor: 'pointer',
            }}
          >
            All
          </Box> */}
          {/* This is how not selected filter looks like */}
          {/* <Box
            sx={{
              display: 'inline',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontSize: '15px',
              fontWeight: 'bold',
              mb: 2,
              p: 1,
              pl: 0,
              mr: 2,
              cursor: 'pointer',
            }}
          >
            Papers
          </Box> */}
          {/* /* Add all the filter JSX with also ability to change filter state on click, the filters are 'all', 'articles', 'websites', 'fda drugs' and 'clinical trials'  */}
          <Box
            sx={{
              display: 'inline',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontSize: '15px',
              fontWeight: 'bold',
              borderBottom: filter === 'all' ? '4px solid #935FC8' : '',
              mb: 2,
              p: 1,
              pl: 0,
              mr: 2,
              cursor: 'pointer',
            }}
            onClick={() => setFilter('all')}
          >
            All ({querySources.length})
          </Box>
          <Box
            sx={{
              display: 'inline',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: filter === 'article' ? '4px solid #935FC8' : '',
              fontSize: '15px',
              fontWeight: 'bold',
              mb: 2,
              p: 1,
              pl: 0,
              mr: 2,
              cursor: 'pointer',
            }}
            onClick={() => setFilter('article')}
          >
            Papers (
            {querySources.filter((source) => source.type === 'article').length})
          </Box>
          <Box
            sx={{
              display: 'inline',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: filter === 'website' ? '4px solid #935FC8' : '',
              fontSize: '15px',
              fontWeight: 'bold',
              mb: 2,
              p: 1,
              pl: 0,
              mr: 2,
              cursor: 'pointer',
            }}
            onClick={() => setFilter('website')}
          >
            Websites (
            {querySources.filter((source) => source.type === 'website').length})
          </Box>
        </Box>
        {filteredSources?.map((source, index) => {
          return (
            <SourceBubble
              key={index}
              authors={source.authors}
              title={source.title}
              url={source.url}
              figures={source.figures}
              relevantContent={source.relevantContent}
              type={source.type}
            />
          );
        })}
      </>
    );
  }
  return <></>;
};

export default Sources;
