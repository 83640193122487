import React from 'react';
import { Box, Typography } from '@mui/material';
import ChatInputBox from './ChatInputBox';
import ChatBubbles from './ChatBubbles';
import { useAppSelector } from '../hooks/redux';

const MainContentChat: React.FC = () => {
  const queriesStore = useAppSelector((state) => state.queriesStore);
  const hasChat = queriesStore.queries.length > 0;
  const scrollRef = React.useRef(null); // Step 1: Create a ref

  React.useEffect(() => {
    // Step 2: Use useEffect to react to changes in `queries`
    if (scrollRef.current) {
      const element = scrollRef.current;
      (element as HTMLDivElement).scrollTop = (
        element as HTMLDivElement
      ).scrollHeight; // Scroll to the bottom
    }
  }, [queriesStore.queries]); // Dependency array, this effect runs when `queries` changes

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'montserrat',
        height: '100vh',
      }}
    >
      <Box
        ref={scrollRef}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: hasChat ? 'flex-start' : 'center', // Align items to the top if hasChat is true, otherwise center them
          alignItems: hasChat ? 'flex-start' : 'center', // Center items horizontally if hasChat is false
          p: 3, // Add padding
          overflowY: 'auto',
          // Custom scrollbar styles for WebKit-based browsers (Chrome, Safari, newer versions of Edge)
          '&::-webkit-scrollbar': {
            width: '0px', // Adjust the width of the scrollbar
          },
        }}
      >
        {hasChat ? (
          <ChatBubbles />
        ) : (
          <Typography
            variant="h5"
            sx={{
              background: 'linear-gradient(to right, #6C61B7, #877987)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
            }}
          >
            What do you want to invent today ?
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          flexGrow: 0, // Keep this area at the bottom
          p: 3, // Add padding
        }}
      >
        <ChatInputBox />
      </Box>
    </Box>
  );
};

export default MainContentChat;
