import { Box, IconButton } from '@mui/material';
import React, { useMemo } from 'react';
import ChatInput from '../Components/ChatInput';
import InitialScreen from '../Components/InitialScreen';
import Messages from '../Components/Messages';
import axios from 'axios';
import { LogoutOutlined } from '@mui/icons-material';
import { getAuth, signOut } from 'firebase/auth';
import { setLoadingAuth } from '../store/reducers/userReducer';
import { useAppDispatch } from '../hooks/redux';
import { isDevelopment } from '../Utils/isDevelopment';
import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import SwipeableDrawer from '../Components/SwipableDrawer';
let baseURL = 'https://us-central1-aneesi-prod.cloudfunctions.net/orchestrator';

if (isDevelopment) baseURL = 'http://localhost:8082';

const Chat = () => {
  const [inputValue, setInputValue] = React.useState('');
  const [chatHistory, setChatHistory] = React.useState<any>([]);
  const [isSystemTyping, setIsSystemTyping] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState<any[]>([]);
  const auth = useMemo(() => getAuth(), []);

  const dispatch = useAppDispatch();

  const boxRef = React.useRef(null);

  React.useEffect(() => {
    if (boxRef.current) {
      (boxRef.current as any).scrollTop = (boxRef.current as any).scrollHeight;
    }
  }, [chatHistory]); // Dependency array contains 'children', so it runs when children change

  const getScreen = () => {
    if (chatHistory && chatHistory.length > 0)
      return (
        <Messages chatHistory={chatHistory} isSystemTyping={isSystemTyping} />
      );
    return <InitialScreen />;
  };

  const handleLogout = async () => {
    signOut(auth);
    dispatch(setLoadingAuth());
  };

  const handleGeneration = async (inputValue: string, queryId: string) => {
    setIsSystemTyping(true);

    const response = await axios.post(
      baseURL + '/api/v1/workflow-instances/run',
      {
        workflowId: '25000aa3-ee6b-409f-a842-f51b8cb6cd8a',
        request: {
          user_input: inputValue,
        },
      }
    );

    // understand query
    const infoMsgPromise = axios
      .post(baseURL + '/api/v1/workflow-instances/run', {
        workflowId: 'ff2f39b2-9ddc-4442-a021-b8b355130bc6',
        request: {
          user_input: inputValue,
        },
      })
      .then((res) => {
        setChatHistory((prev: any) => [
          ...prev,
          {
            message: res.data.Chainer.text,
            isUser: false,
            type: 'informative',
          },
        ]);
      });

    // test for google search
    let googlePromise;
    if (response.data.splitter?.google_search === true) {
      setChatHistory((prev: any) => [
        ...prev,
        {
          message: 'Reading relevant websites..',
          isUser: false,
        },
      ]);
      googlePromise = axios
        .post(baseURL + '/api/v1/workflow-instances/run', {
          workflowId: 'c3905eab-f5b8-4ab1-9b75-c3fc7ce13e9a',
          request: {
            user_input: inputValue,
          },
        })
        .then((googleResponse) => {
          if (googleResponse.data.output.length > 0) {
            const messageId = uuidv4();
            setChatHistory((prev: any) => [
              ...prev,
              {
                type: 'google_results',
                message: googleResponse.data.output.filter(
                  (res: any) => res.summary && res.website
                ),
                isUser: false,
                id: messageId,
              },
            ]);
            setMenuItems((prev) =>
              prev.map((item) => {
                if (item.id === queryId) {
                  return {
                    ...item,
                    websites: {
                      total: googleResponse.data.output.length,
                      id: messageId,
                    },
                  };
                }
                return item;
              })
            );
          }
        });
    }

    // test for google images
    let googleImagesPromise;
    if (response.data.splitter?.google_images === true) {
      setChatHistory((prev: any) => [
        ...prev,
        {
          message: 'Analysing relevant images..',
          isUser: false,
        },
      ]);
      googleImagesPromise = axios
        .post(baseURL + '/api/v1/workflow-instances/run', {
          workflowId: 'cfcf3754-ff80-4cc1-a6f3-2273b466774e',
          request: {
            user_input: inputValue,
          },
        })
        .then((googleResponse) => {
          if (typeof googleResponse.data.output === 'string')
            googleResponse.data.output = JSON.parse(googleResponse.data.output);
          if (googleResponse.data.output.length > 0) {
            const messageId = uuidv4();
            setChatHistory((prev: any) => [
              ...prev,
              {
                type: 'images',
                message: googleResponse.data.output,
                isUser: false,
                id: messageId,
              },
            ]);
            setMenuItems((prev) =>
              prev.map((item) => {
                if (item.id === queryId) {
                  return {
                    ...item,
                    images: {
                      total: googleResponse.data.output.length,
                      id: messageId,
                    },
                  };
                }
                return item;
              })
            );
          }
        });
    }

    // test for pubmed
    let pubMedPromise;
    if (response.data.splitter?.pubmed === true) {
      setChatHistory((prev: any) => [
        ...prev,
        {
          message: 'Reading relevant PubMed articles..',
          isUser: false,
        },
      ]);
      pubMedPromise = axios
        .post(baseURL + '/api/v1/workflow-instances/run', {
          workflowId: 'ec3cbe75-5804-42fa-b66a-32e35575026f',
          request: {
            user_input: inputValue,
          },
        })
        .then((pubMedResponse) => {
          if (pubMedResponse.data.output.length > 0) {
            const messageId = uuidv4();
            setChatHistory((prev: any) => [
              ...prev,
              {
                type: 'pubmed_result',
                message: pubMedResponse.data.output,
                isUser: false,
                id: messageId,
              },
            ]);
            setMenuItems((prev) =>
              prev.map((item) => {
                if (item.id === queryId) {
                  return {
                    ...item,
                    pubmed_articles: {
                      total: pubMedResponse.data.output.length,
                      id: messageId,
                    },
                  };
                }
                return item;
              })
            );
          }
        });
    }

    // test for clinical trials
    let clinicalTrialPromise;
    if (response.data.splitter?.clinical_trials === true) {
      setChatHistory((prev: any) => [
        ...prev,
        {
          message: 'Finding relevant Clinical Trials ..',
          isUser: false,
        },
      ]);
      clinicalTrialPromise = axios
        .post(baseURL + '/api/v1/workflow-instances/run', {
          workflowId: 'afcc7e3b-9ccb-4d43-93b4-e8656d9e7ea3',
          request: {
            user_input: inputValue,
          },
        })
        .then((clinicalTrialsResponse) => {
          if (clinicalTrialsResponse.data.find_clinical_trials.length > 0) {
            const messageId = uuidv4();
            setChatHistory((prev: any) => [
              ...prev,
              {
                type: 'clinical_trial',
                message: clinicalTrialsResponse.data.find_clinical_trials,
                isUser: false,
                id: messageId,
              },
            ]);
            setMenuItems((prev) =>
              prev.map((item) => {
                if (item.id === queryId) {
                  console.log({
                    dddd: clinicalTrialsResponse.data.find_clinical_trials,
                  });
                  return {
                    ...item,
                    clinical_trials: {
                      total:
                        (Array.isArray(
                          clinicalTrialsResponse.data.find_clinical_trials
                        ) &&
                          clinicalTrialsResponse.data.find_clinical_trials
                            .length) ||
                        0,
                      id: messageId,
                    },
                  };
                }
                return item;
              })
            );
          }
        });
    }

    // test for fda drugs
    let fdaDrugsPromise;
    if (response.data.splitter?.fda_drugs === true) {
      setChatHistory((prev: any) => [
        ...prev,
        {
          message: 'Finding relevant FDA approved drugs ..',
          isUser: false,
        },
      ]);
      fdaDrugsPromise = axios
        .post(baseURL + '/api/v1/workflow-instances/run', {
          workflowId: '7e713a5e-9e1c-4b5c-9c62-dd199dafd380',
          request: {
            user_input: inputValue,
          },
        })
        .then((fda_drugs_response) => {
          if (fda_drugs_response.data.search_fda_drugs.length > 0) {
            const messageId = uuidv4();
            setChatHistory((prev: any) => [
              ...prev,
              {
                type: 'fda_drugs',
                message: fda_drugs_response.data.search_fda_drugs,
                isUser: false,
                id: messageId,
              },
            ]);
            setMenuItems((prev) =>
              prev.map((item) => {
                if (item.id === queryId) {
                  return {
                    ...item,
                    fda_drugs: {
                      total: fda_drugs_response.data.search_fda_drugs.length,
                      id: messageId,
                    },
                  };
                }
                return item;
              })
            );
          }
        });
    }

    Promise.allSettled([
      googlePromise,
      googleImagesPromise,
      pubMedPromise,
      clinicalTrialPromise,
      fdaDrugsPromise,
      infoMsgPromise,
    ]).then(() => {
      setIsSystemTyping(false);
    });
  };

  const handleSubmit = () => {
    const queryId = uuidv4();
    setMenuItems((prev) => [
      ...prev,
      {
        id: queryId,
        message: inputValue,
      },
    ]);
    handleGeneration(inputValue, queryId);
    setChatHistory((prev: any) => [
      ...prev,
      {
        message: inputValue,
        isUser: true,
        id: queryId,
        type: 'user_query',
      },
    ]);
    setInputValue('');
  };

  console.log(menuItems);

  return (
    <>
      {/* First column (1/4) */}
      <SwipeableDrawer>
        <Box sx={{ padding: '10px', height: '90vh' }}>
          <Box>
            <h2>Line of thought</h2>
          </Box>
          <TreeView
            aria-label="Line of thought navigation"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={menuItems.map((item) => item.id)}
            sx={{
              height: '100%',
              flexGrow: 1,
              maxWidth: 400,
              overflowX: 'hidden',
              overflowY: 'auto',
              '& .Mui-selected': {
                backgroundColor: 'transparent !important', // Remove the background color
                '&:hover': {
                  backgroundColor: 'transparent', // Also target the hover state if needed
                },
              },
            }}
          >
            {menuItems.map((item, idx) => (
              <TreeItem
                nodeId={item.id}
                key={idx}
                label={item.message}
                onClick={() => {
                  window.location.hash = item.id;
                }}
              >
                {/* <a href={'#' + item.id}>{item.message}</a> */}

                {[
                  'websites',
                  'pubmed_articles',
                  'clinical_trials',
                  'images',
                  'fda',
                ].map((type) => {
                  if (item[type]) {
                    return (
                      <TreeItem
                        nodeId={item[type].id}
                        key={type}
                        label={type + ' (' + item[type].total + ')'}
                        onClick={() => {
                          window.location.hash = item[type].id;
                        }}
                      >
                        {/* <a href={'#' + item[type].id}>
                          {item[type].total} {type}
                        </a> */}
                      </TreeItem>
                    );
                  }
                  return null;
                })}
              </TreeItem>
            ))}
          </TreeView>
        </Box>
      </SwipeableDrawer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height: '40px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            overflow: 'hidden',
          }}
        >
          <IconButton
            sx={{ margin: '4px', cursor: 'pointer' }}
            onClick={handleLogout}
          >
            <LogoutOutlined />
          </IconButton>
        </Box>
        <Box
          ref={boxRef}
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none', // hides scrollbars in WebKit browsers like Chrome and Safari
            },
            scrollbarWidth: 'none', // hides scrollbars in Firefox
            msOverflowStyle: 'none', // hides scrollbars in IE and Edge
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              maxWidth: '800px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {getScreen()}
          </Box>
        </Box>
        <Box
          sx={{
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ChatInput
            setInputValue={setInputValue}
            inputValue={inputValue}
            handleSubmit={handleSubmit}
          />
        </Box>
      </Box>
    </>
  );
};

export default Chat;
