// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfigProd = {
  apiKey: 'AIzaSyC-4rkZWugnQqZffsED8MSEwKhvrPA1KVI',
  authDomain: 'aneesi-prod.firebaseapp.com',
  databaseURL: 'https://aneesi-prod-default-rtdb.firebaseio.com',
  projectId: 'aneesi-prod',
  storageBucket: 'aneesi-prod.appspot.com',
  messagingSenderId: '174039617200',
  appId: '1:174039617200:web:2803838e9cfbff3e98f575',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyC-4rkZWugnQqZffsED8MSEwKhvrPA1KVI',
  authDomain: 'aneesi-prod.firebaseapp.com',
  databaseURL: 'https://aneesi-prod-default-rtdb.firebaseio.com',
  projectId: 'aneesi-prod',
  storageBucket: 'aneesi-prod.appspot.com',
  messagingSenderId: '174039617200',
  appId: '1:174039617200:web:2803838e9cfbff3e98f575',
};

const isDevelopment = true;

const firebaseConfig = isDevelopment ? firebaseConfigDev : firebaseConfigProd;

// Initialize Firebase
export const firebaseApp = () => initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp());
