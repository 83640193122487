import { useAppSelector } from '../hooks/redux';
import useAuthHandler from '../hooks/useAuthHandler';
import Chat from './Chat';
import Login from './Login';
import Project from './Project';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Project />,
  },
  {
    path: 'old',
    element: <Chat />,
  },
]);

const PageContainer = () => {
  useAuthHandler();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  if (isLoggedIn) {
    return <RouterProvider router={router} />;
  }

  return <Login />;
};

export default PageContainer;
