import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { v4 as uuidv4 } from 'uuid';
import { createQuery, processQuery } from '../store/reducers/queriesReducer';
import {
  closeSourcesDrawer,
  openSourcesDrawer,
  setSourcesDrawerQueryId,
} from '../store/reducers/sourcesDrawerReducer';
import tube from '../Assets/mdi_test-tube.png';
import TextSlider from './TextSlider';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const loadingSeconds = 50;

const ChatBubble: React.FC<{
  title: string;
  text: string;
  followUps: string[];
  queryId: string;
  loading: boolean;
  docsFound: number;
}> = ({ title, text, loading, followUps, queryId, docsFound }) => {
  const drawerSettings = useAppSelector((state) => state.sourcesDrawer);
  const dispatch = useAppDispatch();
  const username = useAppSelector((state) => state.user.username);
  const addNewQuery = (input: string) => {
    const queryId = uuidv4();
    dispatch(createQuery({ question: input, id: queryId }));
    dispatch(processQuery(queryId));
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'raw',
          color: '#000000',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: '14',
          fontFamily: 'montserrat',
          mb: 2,
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: '32px', // Adjust size as needed
            height: '32px', // Adjust size as needed
            borderRadius: '50%',
            bgcolor: '#C59090',
            display: 'flex',
            mr: 1,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1', // Ensure this circle is above the next one
            color: '#FFFFFF',
            fontSize: '13px',
          }}
        >
          {username.charAt(0).toUpperCase()}
        </Box>
        {username}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#FFFFFF',
          borderRadius: '10px',
          p: 2,
          mb: 2, // Margin bottom for spacing between bubbles
          position: 'relative', // Needed for absolute positioning of the pseudo-element
          overflow: 'hidden', // Ensures the pseudo-element respects the border radius
          color: '#343134',
          fontSize: '15px',
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'raw',
          color: '#000000',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: '14',
          fontFamily: 'montserrat',
          mb: 2,
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: '32px', // Adjust size as needed
            height: '32px', // Adjust size as needed
            borderRadius: '50%',
            bgcolor: '#6561B4',
            display: 'flex',
            mr: 1,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1', // Ensure this circle is above the next one
            color: '#FFFFFF',
            fontSize: '13px',
          }}
        >
          D
        </Box>
        Deriva
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#ffffff',
          borderRadius: '10px',
          p: 2,
          mb: 2, // Margin bottom for spacing between bubbles
          position: 'relative', // Needed for absolute positioning of the pseudo-element
          overflow: 'hidden', // Ensures the pseudo-element respects the border radius
          '&:after': !loading
            ? {}
            : {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '0%', // Start with 0% width
                height: '16px', // Gradient height
                backgroundImage: 'linear-gradient(to right, #EA5BEC, #5E62B1)',
                borderRadius: '0 0 10px 10px', // Match the parent border-radius at the bottom
                animation: `fillWidth ${loadingSeconds}s cubic-bezier(0, 0, 0, 1) forwards`, // 2s animation to fill width
              },
          '@keyframes fillWidth': {
            '0%': { width: '0%' },
            '100%': { width: '95%' }, // Fill to 100% width
          },
        }}
      >
        {loading ? (
          <Box
            sx={{
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src={tube} style={{ margin: '10px' }} alt="tube icon" />
            <TextSlider
              texts={[
                'Understanding question context..',
                'Generating keyword variations',
                'Searching PubMed',
                'Reading articles',
                'Searching for relevant websites',
                'Memorizing articles',
                'Memorizing websites',
                'Analysing articles',
                'Cross-referencing articles',
                'Generating Suggestions',
                'Generating response',
              ]}
              duration={loadingSeconds}
            />
          </Box>
        ) : (
          <Box>
            {/* Actions */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                {/* Button with #8F8F8F background, 13px font written in it 'View Resources' and before the text an eye icon */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: '#F0EFEF',
                    color: '#5F5D5D',
                    borderRadius: '5px',
                    fontSize: '12px',
                    p: 1,
                    cursor: 'pointer',
                    // pr: 2,
                    // pl: 2,
                    height: '25px',
                    '&:hover': {
                      // make the background more dark
                      bgcolor: '#D1D1D1',
                    },
                  }}
                  onClick={() => {
                    if (
                      drawerSettings.queryId === queryId &&
                      drawerSettings.sourcesDrawerOpen === true
                    ) {
                      console.log('should close');
                      dispatch(closeSourcesDrawer());
                      dispatch(setSourcesDrawerQueryId(''));
                    } else if (!loading) {
                      dispatch(setSourcesDrawerQueryId(queryId));
                      dispatch(openSourcesDrawer());
                    }
                  }}
                >
                  <RemoveRedEyeIcon
                    sx={{ fontSize: '14px', color: '#5F5D5D', mr: 1 }}
                  />
                  View Resources ({docsFound})
                </Box>
              </Box>
              <Box>
                {/* Icons for actions */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton sx={{ color: '#8F8F8F' }}>
                    <ContentCopyIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  <IconButton sx={{ color: '#8F8F8F' }}>
                    <ShareIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  <IconButton sx={{ color: '#8F8F8F' }}>
                    <OpenInFullIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  <IconButton sx={{ color: '#8F8F8F' }}>
                    <AltRouteIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {/* Text */}
            <Typography
              sx={{
                pt: 2,
                color: '#343134',
                fontFamily: 'Montserrat',
                fontSize: '15px',
                mb: 5,
              }}
              variant="body1"
            >
              {text}
            </Typography>
            {/* Suggestions Area */}
            <Box sx={{ fontWeight: 'bold', fontSize: '14px', mb: 1 }}>
              Suggested follow-ups:
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
              {followUps.map((followUp, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '100%',
                    bgcolor: '#F6F6F6',
                    color: '#000000',
                    p: 2,
                    mb: 0.5,
                    borderRadius: '10px',
                    fontSize: '15px',
                    '&:hover': {
                      opacity: 0.7,
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    addNewQuery(followUp);
                  }}
                >
                  {followUp}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ChatBubble;
