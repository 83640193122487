import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';

const ChatInput: React.FC<any> = ({
  setInputValue,
  inputValue,
  handleSubmit,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', width: 800, padding: '3px' }}
    >
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="What is STXBP1?"
        value={inputValue}
        onChange={handleInputChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
            e.preventDefault(); // Prevent the default action to avoid form submission if inside a form
          }
        }}
        sx={{
          '.MuiOutlinedInput-root': {
            borderRadius: '4px',
            border: '1px solid #A6A6A6',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#A6A6A6', // prevents color change on hover/focus
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#A6A6A6', // prevents color change on hover
            },
            fieldset: {
              borderWidth: '0 !important', // removes additional border
            },
          },
          '.MuiInputBase-input': {
            padding: '15px', // Add padding to the text inside the input
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleSubmit} edge="end">
              <SendIcon />
            </IconButton>
          ),
          startAdornment: (
            <IconButton edge="start">
              <SettingsIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default ChatInput;
