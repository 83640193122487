import React from 'react';
import { Global } from '@emotion/react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { grey } from '@mui/material/colors';

const drawerBleeding = 56;

const SwipeableEdgeDrawer: React.FC<any> = ({ children }) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? grey[100]
            : theme.palette.background.default,
      }}
    >
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            '@media (max-width: 600px)': {
              // Assuming 'sm' breakpoint
              width: `calc(100% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
            '@media (min-width: 601px)': {
              // Assuming 'md' breakpoint starts at 601px
              width: `calc(25% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          },
        }}
      />

      <Box
        sx={{
          position: open ? 'absolute' : 'fixed',
          right: open ? -drawerBleeding : 0, // Adjust this if needed to ensure visibility
          left: open ? null : 0,
          top: 0,
          bottom: 0,
          width: drawerBleeding,
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          visibility: open ? 'hidden' : 'visible',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          zIndex: 1300, // Ensure it's on top of other elements; adjust as needed
        }}
        onClick={() => toggleDrawer(!open)}
      >
        <Box
          sx={{
            width: 6,
            height: 30,
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? grey[300] : grey[900],
            borderRadius: 3,
            cursor: 'pointer',
          }}
        />
      </Box>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        variant="temporary"
        ModalProps={{
          keepMounted: true,
          BackdropProps: { invisible: true },
          disableEnforceFocus: true,
          disableAutoFocus: true,
          disableRestoreFocus: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            borderRight: 'none', // Remove the right border
          },
        }}
        elevation={0}
      >
        <Box
          sx={{
            position: open ? 'absolute' : 'fixed',
            right: open ? -drawerBleeding : 0, // Adjust this if needed to ensure visibility
            left: open ? null : 0,
            top: 0,
            bottom: 0,
            width: drawerBleeding,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            visibility: 'visible',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            zIndex: 1300, // Ensure it's on top of other elements; adjust as needed
          }}
          onClick={() => toggleDrawer(!open)}
        >
          <Box
            sx={{
              width: 6,
              height: 30,
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? grey[300] : grey[900],
              borderRadius: 3,
              cursor: 'pointer',
            }}
          />
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? '#fff' : grey[800],
          }}
        >
          {children}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default SwipeableEdgeDrawer;
