import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

type TextSlideProps = {
  texts: string[];
  duration: number;
};
const TextSlider: React.FC<TextSlideProps> = ({ texts, duration }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (texts.length === 0) return;
    // random value between 1000 and 2000
    const randomDuration = Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000;
    const intervalTime = (duration / texts.length) * 1000 + randomDuration;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        // Clear interval when the last text is being displayed
        if (nextIndex === texts.length - 1) {
          clearInterval(interval);
        }
        return nextIndex < texts.length ? nextIndex : prevIndex;
      });
    }, intervalTime);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [texts, duration]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100px', // Adjust based on your needs
        width: '100%', // Adjust based on your needs
        overflow: 'hidden',
        '& .text-slider-item': {
          animation: 'slideIn 1s ease forwards',
          '@keyframes slideIn': {
            from: {
              transform: 'translateY(100%)',
              opacity: 0,
            },
            to: {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
        },
      }}
      key={currentIndex}
    >
      <div
        className="text-slider-item"
        style={{
          background: 'linear-gradient(to right, #E95AEC, #6C61B7)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
        }}
      >
        {texts[currentIndex]}
      </div>
    </Box>
  );
};

export default TextSlider;
