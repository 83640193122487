import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FilterListIcon from '@mui/icons-material/FilterList';
import SendIcon from '@mui/icons-material/Send';
import { useAppDispatch } from '../hooks/redux';
import { createQuery, processQuery } from '../store/reducers/queriesReducer';
import { v4 as uuidv4 } from 'uuid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import { Check } from '@mui/icons-material';

type FilterOptions = {
  articles: boolean;
  websites: boolean;
  clinicalTrials: boolean;
  fdaDrugs: boolean;
};

const ChatInputBox: React.FC = () => {
  const [chatInput, setChatInput] = useState<string>('');

  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filters, setFilters] = React.useState<FilterOptions>({
    articles: true, // Just as an example, set some to true and others to false
    websites: true,
    clinicalTrials: false,
    fdaDrugs: false,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const addNewQuery = (hasChat: boolean) => {
    const queryId = uuidv4();
    dispatch(createQuery({ question: chatInput, id: queryId }));
    dispatch(processQuery({ id: queryId, filters }));
    setChatInput('');
  };

  const handleMenuItemClick = (filterName: keyof FilterOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
    // No need to call handleClose here if you want the menu to stay open after toggling an option
  };

  return (
    <Box
      sx={{
        flexGrow: 0,
        border: '2px solid #877987',
        bgcolor: '#ffffff',
        height: '160px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px', // Added border-radius
      }}
    >
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your question here..."
          sx={{
            '& .MuiOutlinedInput-root': {
              color: '#545454',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px',
              '& fieldset': { border: 'none' },
              '&:hover fieldset': { border: 'none' },
              '&.Mui-focused fieldset': { border: 'none' },
            },
            bgcolor: '#ffffff', // Match the background
          }}
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          onKeyDown={(e) => {
            // if key is enter
            if (e.key === 'Enter') {
              addNewQuery(true);
            }
          }}
          autoComplete="off"
        />
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          height: '60px',
          bgcolor: '#ECECEC',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // Adjusted for icon placement
          p: 1,
          borderRadius: '0 0 8px 8px', // Rounded corners only at the bottom
        }}
      >
        <Box>
          <IconButton sx={{ color: '#877987' }}>
            <AttachFileIcon />
          </IconButton>
          <IconButton sx={{ color: '#877987' }} onClick={handleClick}>
            <FilterListIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            sx={{ color: '#877987' }}
            onClick={() => addNewQuery(true)}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(filters).map(([key, value]) => (
          <MenuItem
            key={key}
            onClick={() => handleMenuItemClick(key as keyof FilterOptions)}
          >
            <>
              {value && (
                <ListItemIcon>
                  <Checkbox
                    icon={<span />}
                    checkedIcon={
                      <Check sx={{ fontSize: '12px', color: '#000' }} />
                    }
                    checked={true}
                    disabled
                  />
                </ListItemIcon>
              )}
              <Box sx={{ paddingLeft: value ? 0 : '24px', fontSize: '14px' }}>
                {' '}
                {/* Adjust padding based on presence of checkbox */}
                {key.charAt(0).toUpperCase() +
                  key.slice(1).replace(/([A-Z])/g, ' $1')}
              </Box>
            </>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ChatInputBox;
