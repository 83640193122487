import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  sourcesDrawerOpen: boolean;
  queryId: string;
}

const initialState: IInitialState = {
  sourcesDrawerOpen: false,
  queryId: '',
};

export const sourcesDrawerSlice = createSlice({
  name: 'sourcesDrawer',
  initialState,
  reducers: {
    openSourcesDrawer: (state) => {
      state.sourcesDrawerOpen = true;
      return state;
    },
    closeSourcesDrawer: (state) => {
      state.sourcesDrawerOpen = false;
      return state;
    },
    setSourcesDrawerQueryId: (state, action: PayloadAction<string>) => {
      state.queryId = action.payload;
      return state;
    },
  },
});

export const {
  openSourcesDrawer,
  closeSourcesDrawer,
  setSourcesDrawerQueryId,
} = sourcesDrawerSlice.actions;

export default sourcesDrawerSlice.reducer;
