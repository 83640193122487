import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

interface IInitialState {
  queries: IQuery[];
}

type IQuery = {
  question: string;
  answer: string;
  id: string;
  sources: Source[];
  loading: boolean;
  followUps: string[];
};

type Source = {
  url: string;
  authors: string[];
  relevantContent: string;
  figures: Figure[];
  title: string;
  type: string;
};

type Figure = {
  url: string;
  caption: string;
};

const initialState: IInitialState = {
  queries: [],
};

export const queriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    createQuery: (
      state,
      action: PayloadAction<{ question: string; id: string }>
    ) => {
      const newQuery: IQuery = {
        question: action.payload.question,
        answer: '',
        id: action.payload.id,
        sources: [],
        loading: true,
        followUps: [],
      };
      state.queries.push(newQuery);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(processQuery.fulfilled, (state, action) => {
      // handle adding new query
      const query = state.queries.find(
        (query) => query.id === action.meta.arg.id
      );
      if (query) {
        query.answer = action.payload.answer.answer;
        query.loading = false;
        // if the suggested_questions is array of strings then add it
        // to followUps else ignore it
        if (Array.isArray(action.payload.suggest_questions))
          query.followUps = action.payload.suggest_questions;
        // remove duplications of docs with the same doc.metadata.source
        let docsFiltered = action.payload.answer.docs.filter(
          (doc: any, index: number, self: any) =>
            index ===
            self.findIndex(
              (t: any) => t.metadata.source === doc.metadata.source
            )
        );
        // for each doc in docs filtered, find all the docs in action.payload.answer.docs that have the same doc.metadata.source
        // and add their text to it so it will contain all the text of the docs with the same source
        docsFiltered = docsFiltered.map((doc: any) => {
          const docs = action.payload.answer.docs.filter(
            (t: any) => t.metadata.source === doc.metadata.source
          );
          doc['page_content'] = docs.map((doc: any) => doc['page_content']);
          doc['page_content'] = doc['page_content'].join(' // ');
          return doc;
        });
        query.sources = docsFiltered.map((doc: any) => {
          return {
            url: doc.metadata.url,
            authors: doc.metadata?.authors || [],
            relevantContent: doc['page_content'],
            // doc metadata title if exists or cut the first 12 letters chars of the url
            title: doc.metadata?.title || doc.metadata?.url.slice(12),
            figures: doc.metadata?.figures
              ? doc.metadata.figures.map((figure: any) => ({
                  url: figure.src,
                  caption: figure.caption,
                }))
              : [],
            type: doc.metadata.satellite,
          };
        });
      }

      return state;
    });
    builder.addCase(processQuery.pending, (state) => {
      // handle loading
    });
    builder.addCase(processQuery.rejected, (state, action) => {
      // handle error
    });
  },
});

// Action creators are generated for each case reducer function
export const { createQuery } = queriesSlice.actions;

let baseURL = 'https://us-central1-aneesi-prod.cloudfunctions.net/orchestrator';

// if (isDevelopment) baseURL = 'http://localhost:8082';

// async thunks for creating a new query
export const processQuery = createAsyncThunk(
  'queries/createQuery',
  async ({ id, filters }: any, thunkAPI) => {
    // get state
    const state = thunkAPI.getState() as { queriesStore: IInitialState };
    // get query by id

    // if all filters are false the workflow id = fdfe5e75-5677-43ac-86e3-d58439527987
    // else the workflow id = ec3cbe75-5804-42fa-b66a-32e35575026f
    let workflow_id = 'ec3cbe75-5804-42fa-b66a-32e35575026f';
    if (Object.values(filters).every((filter) => filter === false)) {
      workflow_id = 'fdfe5e75-5677-43ac-86e3-d58439527987';
    }
    const query = state.queriesStore.queries.find((query) => query.id === id);
    console.log('starting workflow');
    const response = await axios.post(
      baseURL + '/api/v1/workflow-instances/run',
      {
        workflowId: workflow_id,
        request: {
          user_input: query?.question,
          ...filters,
        },
      }
    );
    console.log(response.data);
    return response.data;
  }
);

export default queriesSlice.reducer;
