import React from 'react';
import { Box } from '@mui/material';
import ChatBubble from './ChatBubble';
import { useAppSelector } from '../hooks/redux';

const ChatBubbles: React.FC = () => {
  const queriesStore = useAppSelector((state) => state.queriesStore);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {queriesStore.queries.map((query) => (
        <ChatBubble
          key={query.id}
          title={query.question}
          text={query.answer}
          loading={query.loading}
          followUps={query.followUps}
          queryId={query.id}
          docsFound={query.sources.length}
        />
      ))}
    </Box>
  );
};

export default ChatBubbles;
