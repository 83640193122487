import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import typingAnimation from '../Assets/typing.gif';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LanguageIcon from '@mui/icons-material/Language';
import Carousel from 'react-material-ui-carousel';
import LaunchIcon from '@mui/icons-material/Launch';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ClinicalTrialsTable from './ClinicalTrialsTable';
import FDADrugsTable from './FDADrugsTable';

const Messages: React.FC<any> = ({ chatHistory, isSystemTyping }) => {
  let modifiedHistory = chatHistory;
  if (isSystemTyping)
    modifiedHistory = [
      ...modifiedHistory,
      {
        type: 'loading',
        isUser: false,
      },
    ];
  return (
    <>
      {modifiedHistory.map((messagePayload: any, index: number) => {
        const sameMessenger =
          index - 1 > -1 &&
          chatHistory[index - 1].isUser === messagePayload.isUser;
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: sameMessenger ? '5px' : '20px',
            }}
          >
            <Box sx={{ marginRight: '10px' }}>
              {sameMessenger ? (
                <Box sx={{ width: 32, height: 32 }}> </Box>
              ) : (
                <Avatar
                  sx={{
                    bgcolor: messagePayload.isUser ? '#D9D9D9' : '#3430F9',
                    width: 32,
                    height: 32,
                  }}
                >
                  {' '}
                </Avatar>
              )}
            </Box>
            <Box>
              <Box>
                <b>
                  {messagePayload.isUser
                    ? sameMessenger
                      ? ' '
                      : 'You'
                    : !sameMessenger && 'The Source'}
                </b>
              </Box>
              <Box>
                <MessageContent payload={messagePayload} />
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

const MessageContent = ({ payload }: any) => {
  if (payload?.type === 'loading') {
    return (
      <>
        <img src={typingAnimation} style={{ height: '30px' }} alt="typing" />
      </>
    );
  }

  if (payload?.type === 'google_results') {
    return (
      <Box sx={{ marginTop: '20px' }} id={payload.id}>
        {payload.message.map((result: any, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #D9D9D9',
                borderRadius: '5px',
                padding: '24px',
                marginTop: '12px',
                marginBottom: '12px',
              }}
            >
              <Typography sx={{}}>
                {result.summary}{' '}
                <Tooltip title={result.website}>
                  <a href={result.website} target="_blank" rel="noreferrer">
                    <LanguageIcon color="primary" />
                  </a>
                </Tooltip>
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  }

  if (payload?.type === 'pubmed_result') {
    return (
      <Box
        sx={{
          flexDirection: 'column',
          flex: 1,
        }}
        id={payload.id}
      >
        {payload.message
          .filter((result: any) => !!result.website && !!result.summary)
          .map((result: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #D9D9D9',
                  borderRadius: '5px',
                  padding: '24px',
                  marginTop: '12px',
                  marginBottom: '12px',
                }}
              >
                <Typography sx={{}}>
                  {result.summary}{' '}
                  <Tooltip title={result.website}>
                    <a href={result.website} target="_blank" rel="noreferrer">
                      <FormatQuoteIcon color="primary" />
                    </a>
                  </Tooltip>
                </Typography>
                {result.figures.length > 0 && (
                  <ImagesCarousel
                    images={result.figures.map((res: any) => ({
                      image: res.src,
                      description: res.caption,
                      site_link: res.src,
                    }))}
                  />
                )}
              </Box>
            );
          })}
      </Box>
    );
  }

  if (payload?.type === 'clinical_trial') {
    return (
      <Box sx={{ marginTop: '20px', width: '100%' }} id={payload.id}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #D9D9D9',
            borderRadius: '5px',
            padding: '24px',
            marginTop: '12px',
            marginBottom: '12px',
            width: '100%',
          }}
        >
          <Typography variant="h6" sx={{ paddingBottom: '30px' }}>
            Clinical Trials
          </Typography>
          <ClinicalTrialsTable data={payload.message} />
        </Box>
      </Box>
    );
  }

  if (payload?.type === 'fda_drugs' && payload.message?.length > 0) {
    return (
      <Box sx={{ marginTop: '20px', width: '100%' }} id={payload.id}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #D9D9D9',
            borderRadius: '5px',
            padding: '24px',
            marginTop: '12px',
            marginBottom: '12px',
            width: '100%',
          }}
        >
          <Typography variant="h6" sx={{ paddingBottom: '30px' }}>
            FDA Drugs
          </Typography>
          <FDADrugsTable data={payload.message} />
        </Box>
      </Box>
    );
  }

  if (payload?.type === 'images') {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '800px',
          border: '1px solid #D9D9D9',
          borderRadius: '5px',
          padding: '24px',
          marginTop: '12px',
          marginBottom: '12px',
        }}
        id={payload.id}
      >
        <ImagesCarousel images={payload.message} />
      </Box>
    );
  }

  if (payload.type === 'informative') {
    return (
      <Box
        sx={{
          display: 'flex',
          border: '1px solid #D9D9D9',
          borderRadius: '5px',
          padding: '24px',
          marginTop: '12px',
          marginBottom: '12px',
        }}
      >
        <EmojiObjectsIcon
          sx={{ color: '#FFCC00', marginRight: '20px' }}
          fontSize="large"
        />
        {payload.message}
      </Box>
    );
  }

  if (payload.type === 'user_query')
    return <Box id={payload.id}>{payload.message}</Box>;
  return <Box>{payload.message}</Box>;
};

export const ImagesCarousel = ({ images, showCaption = true }: any) => {
  const anyHasLongText = images
    .map((img: any) => img.description)
    .some((desc: any) => desc && desc.length > 500);
  return (
    <Carousel sx={{ width: '100%' }} stopAutoPlayOnHover={true}>
      {images.map((result: any, index: number) => {
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height:
                showCaption === true && anyHasLongText ? '800px' : '600px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px', // Scrollbar width
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f0f0f0', // Scrollbar track color
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888', // Scrollbar handle color
                borderRadius: '5px', // Scrollbar handle border radius
                '&:hover': {
                  backgroundColor: '#555', // Scrollbar handle color on hover
                },
              },
            }}
          >
            <a href={result.image} target="_blank" rel="noreferrer">
              <img
                src={result.image}
                alt="display"
                style={{ maxHeight: '400px', maxWidth: '500px' }}
              />
            </a>
            {showCaption && (
              <Box sx={{ maxWidth: '500px', overflow: 'scrollY' }}>
                <Typography variant="caption" sx={{ fontSize: '14px' }}>
                  {result.description}{' '}
                </Typography>
                <a href={result.site_link} target="_blank" rel="noreferrer">
                  <LaunchIcon fontSize="small" />
                </a>
              </Box>
            )}
          </Box>
        );
      })}
    </Carousel>
  );
};

export default Messages;
