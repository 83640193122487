import { Box, Button, TextField, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { setLoginError } from '../store/reducers/userReducer';
import { isDevelopment } from '../Utils/isDevelopment';

const isEmailLink = window.location.href.includes('loginwithemaillink');

const Login = () => {
  const provider = useMemo(() => new GoogleAuthProvider(), []);
  const auth = useMemo(() => getAuth(), []);
  const dispatch = useAppDispatch();
  const { loginError } = useAppSelector((state) => state.user);
  const [email, setEmail] = useState('');
  const [sentEmail, setSentEmail] = useState(false);
  useEffect(() => {
    if (isEmailLink) {
      const auth = getAuth();
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let emailCache = window.localStorage.getItem('emailForSignIn');
        if (!emailCache) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          // emailCache = window.prompt(
          //   'Please provide your email for confirmation'
          // );
        }
        console.log(emailCache);

        if (emailCache)
          // The client SDK will parse the code from the link for you.
          signInWithEmailLink(auth, emailCache, window.location.href)
            .then((result) => {
              // Clear email from storage.
              // console.log('removing item');
              // window.localStorage.removeItem('emailForSignIn');
              // You can access the new user via result.user
              // Additional user info profile not available via:
              // result.additionalUserInfo.profile == null
              // You can check if the user is new or existing:
              // result.additionalUserInfo.isNewUser
            })
            .catch((error) => {
              // Some error occurred, you can inspect the code: error.code
              // Common errors could be invalid email and invalid or expired OTPs.
              console.log(error);
              dispatch(
                setLoginError(
                  'Error logging in - did you get an early access ?'
                )
              );
            });
      }
    }
  }, [dispatch]);

  const handleLoginClick = async () => {
    try {
      await signInWithPopup(auth, provider);

      return true;
    } catch (err) {
      dispatch(
        setLoginError('Error logging in - did you get an early access ?')
      );
      return false;
    }
  };

  const handleEmailLoginClick = async () => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `https://${
        isDevelopment ? 'localhost:3000' : 'app.aneesi.ai'
      }/loginwithemaillink`,
      // This must be true.
      handleCodeInApp: true,
    };
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setSentEmail(true);
    } catch (err) {
      dispatch(
        setLoginError('Error logging in - did you get an early access ?')
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: { xs: 'column', md: 'column' },
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1,
          // background: '#F5F5F5',
          display: 'flex',
          // minHeight: { md: '100vh', sm: '' },
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* logo here */}
          <img
            src="https://assets-global.website-files.com/645f502601f06727642885af/65b0ef5fc43e62a2d468a96b_Group%201%20(1).png"
            loading="lazy"
            sizes="(max-width: 479px) 83vw, (max-width: 767px) 84vw, (max-width: 991px) 648px, 860px"
            srcSet="https://assets-global.website-files.com/645f502601f06727642885af/65b0ef5fc43e62a2d468a96b_Group%201%20(1)-p-500.png 500w, https://assets-global.website-files.com/645f502601f06727642885af/65b0ef5fc43e62a2d468a96b_Group%201%20(1)-p-800.png 800w, https://assets-global.website-files.com/645f502601f06727642885af/65b0ef5fc43e62a2d468a96b_Group%201%20(1)-p-1080.png 1080w, https://assets-global.website-files.com/645f502601f06727642885af/65b0ef5fc43e62a2d468a96b_Group%201%20(1).png 1100w"
            alt=""
          />
        </Box>
      </Box>
      {sentEmail ? (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6">
            Please check your email for a sign in link
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ margin: '10px' }}>
            <Typography variant="h6">Sign In</Typography>
          </Box>
          <Box sx={{ margin: '10px' }}>
            <TextField
              placeholder="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{
                minWidth: '300px',
                color: '#000',
                '&:hover': {
                  color: '#000',
                },
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                minWidth: '300px',
                color: '#000',
                background: '#F5F5F5',
                '&:hover': {
                  background: '#F5F5F5',
                  color: '#000',
                },
              }}
              onClick={() => handleEmailLoginClick()}
            >
              <Box sx={{ margin: '5px', fontWeight: 'bold' }}>
                Sign in with Email
              </Box>
            </Button>
          </Box>
          <Box sx={{ margin: '10px' }}>
            <Typography variant="caption">OR</Typography>
          </Box>

          <Box sx={{ margin: '10px' }}>
            <Button
              variant="contained"
              sx={{
                minWidth: '300px',
                color: '#000',
                background: '#F5F5F5',
                '&:hover': {
                  background: '#F5F5F5',
                  color: '#000',
                },
              }}
              onClick={() => handleLoginClick()}
            >
              <GoogleIcon />
              <Box sx={{ margin: '5px', fontWeight: 'bold' }}>
                Sign in with Google
              </Box>
            </Button>
            {loginError && (
              <Box
                sx={{
                  padding: '10px',
                  color: 'red',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption">{loginError}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Login;
