import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

function ClinicalTrialsTable({ data }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (data && Array.isArray(data))
    return (
      <>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Condition</TableCell>
                <TableCell>Brief Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.Rank}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.Rank}
                    </TableCell>
                    <TableCell>{row.Condition.join(', ')}</TableCell>
                    <TableCell>{row.BriefTitle[0]}</TableCell>
                    <TableCell>{row.OverallStatus[0]}</TableCell>
                    <TableCell>
                      <a
                        href={`https://clinicaltrials.gov/study/${row.NCTId[0]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LaunchIcon fontSize="small" />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );

  return <></>;
}

export default ClinicalTrialsTable;
