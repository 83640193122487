import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from './store/store';

import PageContainer from './Pages/PageContainer';
import { firebaseApp } from './config/firebase';

import LogRocket from 'logrocket';
LogRocket.init('drw0l4/chat-interface');

function App() {
  firebaseApp();
  return (
    <>
      <Provider store={store}>
        <CssBaseline />
        <PageContainer />
      </Provider>
    </>
  );
}

export default App;
