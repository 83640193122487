import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WebAssetIcon from '@mui/icons-material/WebAsset';

const SourceBubble: React.FC<{
  title: string;
  authors: string[];
  url: string;
  figures: any;
  relevantContent: string;
  type: string;
}> = ({ title, authors, url, figures, relevantContent, type }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        boxShadow: '0 2px 6px rgba(128, 128, 128, 0.5)',
        p: 2,
        mb: 2, // Margin bottom for spacing between bubbles
        position: 'relative', // Needed for absolute positioning of the pseudo-element
        overflow: 'hidden', // Ensures the pseudo-element respects the border radius
      }}
    >
      {/* Title */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ display: 'flex', flex: 10, alignItems: 'center' }}>
          <Typography
            variant="h4"
            sx={{
              color: '#000',
              fontFamily: 'Montserrat',
              fontSize: '17px',
              mb: 1,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            {type === 'website' ? (
              <WebAssetIcon sx={{ color: '#000', mr: 2, fontSize: '20px' }} />
            ) : (
              <ArticleIcon sx={{ color: '#000', mr: 2, fontSize: '20px' }} />
            )}{' '}
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 2,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            pl: 1,
          }}
        >
          <Tooltip title={'Go to article'}>
            <OpenInNewIcon
              onClick={() => {
                //open the url in blank window
                window.open(url, '_blank');
              }}
              sx={{
                color: '#000',
                mr: 2,
                cursor: 'pointer',
                fontSize: '20px',
              }}
            />
          </Tooltip>
          <Tooltip title={'Favorite article'}>
            <StarOutlineIcon
              sx={{ color: '#000', mr: 2, cursor: 'pointer', fontSize: '20px' }}
            />
          </Tooltip>
          <Tooltip title="More Actions">
            <MoreVertIcon
              sx={{ color: '#000', mr: 2, cursor: 'pointer', fontSize: '20px' }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        {/* Text */}
        <Typography
          sx={{
            color: '#000',
            fontFamily: 'Montserrat',
            fontSize: '15px',
          }}
          variant="body1"
        >
          {relevantContent}
        </Typography>
      </Box>
      {type === 'article' && (
        <Box>
          <Typography
            variant="caption"
            sx={{ fontSize: '13px', color: '#8F8F8F' }}
          >
            Nature | {authors[0].split(',')[0]} et al. | 208 Citations | 2008
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SourceBubble;
