import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

function FDADrugsTable({ data }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="FDA Drugs Table">
          <TableHead>
            <TableRow>
              <TableCell>Brand Name</TableCell>
              <TableCell>Generic Name</TableCell>
              {/* <TableCell>Indications and Usage</TableCell> */}
              <TableCell>Active Ingredient</TableCell>
              <TableCell>Route</TableCell>
              <TableCell>Link to FDA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <>
                  <TableRow
                    key={index} // Since there's no 'Rank', using index as key
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.brand_name}
                    </TableCell>
                    <TableCell>{row.generic_name}</TableCell>

                    <TableCell>{row.active_ingredient}</TableCell>
                    <TableCell>{row.route}</TableCell>
                    <TableCell>
                      {/* Assuming there's a direct link to the FDA page using the product_ndc */}
                      <a
                        href={`https://www.accessdata.fda.gov/scripts/cder/ndc/index.cfm?event=BasicSearch.process&ApplNo=${row.product_ndc}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LaunchIcon fontSize="small" />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4}>
                      {row.indications_and_usage}
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default FDADrugsTable;
