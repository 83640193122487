import { Box, Typography } from '@mui/material';
import React from 'react';
import logo from '../Assets/logo.png';

const InitialScreen = () => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img src={logo} alt="logo" style={{ marginBottom: '20px' }} />
      <Typography variant="h6">What do you want to discover today ?</Typography>
    </Box>
  );
};

export default InitialScreen;
